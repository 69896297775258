.ecotrust-introduction-header {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 4rem 10%;
    align-items: center;
    padding: 5%;
    text-align: center;

    @media screen and (min-width: 40em) {
        & > .body {
            width: 60%;
        }
    }

    img {
        max-width: 50%;
        max-height: 30%;
    }
}

.interactive-measures-view {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        flex-direction: column;
        padding: 4rem 10%;
        align-items: center;

        &__title {
            margin-bottom: 3rem;
        }

        &__dropdown-and-title-view {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.5rem;
            margin-bottom: 2rem;
        }
    }

    &__stream-chart-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 10% 0 10%;

        &__subtitle {
            margin-bottom: 0.5rem;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 24px;
        width: 100%;

        .card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 100px;
            border-radius: 8px;
            background-color: white;
            padding: 1em;
        }
    }
}

// styles for ant design dropdown
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #feb2bf !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    color: #df4661 !important;
}

.ant-select-focused .ant-select-selector {
    border-color: #feb2bf !important;
    outline: none !important;
    box-shadow: 0 0 0 1px #feb2bf !important;
}

.ant-select:hover .ant-select-selector {
    border-color: #feb2bf !important;
    outline: none !important;
}

.ant-select-selection-item-content {
    font-size: 1rem !important;
}

.ant-select-item {
    font-size: 1rem !important;
}

.ant-select-selector {
    font-size: 1rem !important;
}

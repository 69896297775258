$pink: #df4661;

.more-details-table-view {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 3rem 10% 4rem 10%;

    &__title-and-button {
        display: flex;
        align-items: center;
        position: relative;

        &__title {
            width: 100%;
        }

        .filter-button {
            position: absolute;
            display: flex;
            gap: 0.5rem;
            align-items: center;
            right: 0;
            bottom: 0;
            border: 1px solid #d9d9d9;
            padding: 0.5rem 1.5rem;
            border-radius: 0.3em;
            background-color: white;
            font-size: 1.25rem;
        }
    }
}

.filter-slider {
    padding: 16px 32px;
}

// styles for ant design table and modal

.ant-table-thead {
    .ant-table-cell {
        text-align: center !important;
        font-size: 1rem !important;
        font-family: "PoppinsSemiBold" !important;
        background-color: #f4f9fd !important;
        border: 0.5px solid #d9d9d9;
    }
}

.ant-table-tbody {
    .ant-table-cell {
        border: 0.5px solid #d9d9d9;
        text-align: center !important;
        font-size: 1rem !important;
        font-family: "PoppinsRegular" !important;
    }
}

.ant-table {
    border: 0.5px solid #d9d9d9;
}

// styles for ant design modal

.ant-modal-title {
    text-align: center;
    font-size: 1.25rem;
    font-family: "PoppinsRegular";
}

.ant-modal-footer {
    & > .custom-footer {
        display: inline-block;
        width: 100%;

        & > .ok-button {
            float: right;
        }

        & > .cancel-button {
            float: right;
            margin-right: 1em;
        }

        & > .clear-button {
            float: left;
        }
    }
}

.ant-slider-rail {
    background: lighten($pink, 30%) !important;
}

.ant-slider-track {
    background: $pink !important;
}

.ant-slider-mark-text {
    font-size: 1rem !important;
}

.ant-slider:hover .ant-slider-handle {
    border: 1px solid $pink !important;
}

.ant-modal-body {
    font-size: 1rem !important;
}

.ant-slider-handle {
    border: 1px solid $pink;
    &:focus {
        border: 1px solid $pink;
        box-shadow: 0 0 0 5px rgb(234, 135, 153, 0.3) !important;
    }
    &:hover {
        border: 1px solid $pink;
    }
    &::selection {
        border: 1px solid $pink;
    }
}

.benchmark-view {
    display: flex;
    flex-direction: column;
    margin: 4rem 10%;
    gap: 3rem;

    &__body {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
        align-items: center;
        justify-items: center;
        gap: 16px;
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        background-color: #f5f5f5;
        padding: 1em;
        border-radius: 8px;
        gap: 1em;

        &__subtitle {
            display: flex;
            gap: 0.05em;
        }

        &__stats {
            display: flex;
            gap: 0.5em;
            align-items: center;
        }
    }
}

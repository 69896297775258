@font-face {
    font-family: "PoppinsRegular";
    src: local("Poppins"),
        url(./assets/Poppins/Poppins-Regular.ttf) format("opentype");
}

@font-face {
    font-family: "PoppinsBold";
    src: local("Poppins"),
        url(./assets/Poppins/Poppins-Bold.ttf) format("opentype");
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: local("Poppins"),
        url(./assets/Poppins/Poppins-SemiBold.ttf) format("opentype");
}

@font-face {
    font-family: "PoppinsItalic";
    src: local("Poppins"),
        url(./assets/Poppins/Poppins-Italic.ttf) format("opentype");
}

@media screen and (max-width: 65em) {
    html {
        font-size: 0.75em;
    }
}

@media screen and (max-width: 35em) {
    html {
        font-size: 0.5em;
    }
}

$darkBlue: #003b5c;
$pink: #df4661;
$lightGray: #f5f5f5;
$primary-gray: #f2f5f7;
$gray: #575757;

.title {
    &--bold {
        text-align: center;
        font-size: 2rem;
        font-family: "PoppinsSemiBold";
    }
    &--regular {
        text-align: center;
        font-size: 2rem;
        font-family: "PoppinsRegular";
    }
}

.smaller-title {
    &--bold {
        text-align: center;
        font-size: 1.875rem;
        font-family: "PoppinsSemiBold";
    }
    &--regular {
        text-align: center;
        font-size: 1.875rem;
        font-family: "PoppinsRegular";
    }
}

.subtitle {
    &--regular {
        text-align: center;
        font-size: 1.5rem;
        font-family: "PoppinsRegular";
    }
    &--bold {
        text-align: center;
        font-size: 1.5rem;
        font-family: "PoppinsSemiBold";
    }
}

.smaller-subtitle {
    &--regular {
        text-align: center;
        font-size: 1.25rem;
        font-family: "PoppinsRegular";
    }
    &--bold {
        text-align: center;
        font-size: 1.25rem;
        font-family: "PoppinsSemiBold";
    }
}

.semi-large-text {
    &--bold {
        text-align: center;
        font-size: 2.5rem;
        font-family: "PoppinsSemiBold";
    }
}

.large-text {
    &--bold {
        text-align: center;
        font-size: 3.5rem;
        font-family: "PoppinsSemiBold";
    }
}

.body {
    text-align: center;
    font-size: 1.25rem;
    font-family: "PoppinsRegular";

    &__italic {
        text-align: center;
        font-size: 1.25rem;
        font-family: "PoppinsItalic";
    }

    &--bold {
        font-family: "PoppinsSemiBold";
    }
}

.smaller-body {
    &--bold {
        text-align: center;
        font-size: 1rem;
        font-family: "PoppinsSemiBold";
    }
}

.darkblue-background {
    background-color: $darkBlue;
    color: white;
}

.lightgray-background {
    background-color: $lightGray;
}

.gray-background {
    background-color: $primary-gray;
}

.pink-text {
    color: $pink;
}

.lightGrayText {
    color: $gray;
}

.divider {
    border: 0.5px solid #f0f0f0;
    height: 0px;
}

//override ant design button styles

.ant-btn-default {
    &:hover {
        border-color: $pink !important;
        color: $pink !important;
    }
}

.ant-btn-primary {
    background-color: $pink !important;
    border-color: $pink !important;

    &:hover {
        background-color: lighten($pink, 10%) !important;
        border-color: lighten($pink, 10%) !important;
    }
}

.ant-btn-link {
    color: $pink !important;
    padding: 0 !important;
    margin: 0 !important;
}

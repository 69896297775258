.dashboard-summary-view {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    align-items: center;
    background-color: #b1e4e3;
    color: #003b5c;
    height: fit-content;
    margin-bottom: 4rem;

    @media screen and (max-width: 1150px) {
        grid-template-columns: repeat(1, 1fr); 
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__text {
        margin: 10%;
    }
}

.map-view {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;

    &__title {
        align-self: center;
        margin-bottom: 0.5rem;
    }
}

.footer {
    height: fit-content;
    padding: 64px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;

    &__logos {
        display: flex;
        gap: 64px;
        align-items: center;
        justify-content: center;
        height: 100%;

        &__audette {
            height: 24px;
            width: 107px;
        }

        &__rbc {
            width: 77px;
            height: 75px;
        }
    }
}
